<template>
	<HeadingX class="c-base-h4" v-bind="{ tag }" :level-offset="levelOffset">
		<template v-if="domProps.textContent">
			{{ domProps.textContent }}
		</template>
		<template v-else>
			<slot></slot>
		</template>
	</HeadingX>
</template>

<script>
import HeadingX from '~/components/shared/HeadingX';

export default {
	name: 'BaseH4',
	components: { HeadingX },

	props: {
		tag: {
			type: String,
			default: undefined,
		},
		levelOffset: {
			type: [Number, String],
			default: 3,
		},
	},

	computed: {
		domProps() {
			const { domProps } = this.$vnode?.data || {};
			return domProps || {};
		},
	},
};
</script>

<style lang="postcss">
:where(.c-base-h4) {
	@apply text-h4 font-medium whitespace-pre-wrap;
	text-wrap: balance;
}
</style>
